function showWatch()
{
 var time = new Date();
 var minutes = time.getMinutes();
 var seconds = time.getSeconds();
 var hour = time.getHours();
 var timeZone;
 var hourComplete;

 if(hour>12)
  timeZone = 'p.m';
 else
  timeZone = 'a.m';

 if(hour>12)
  hour -= 12;
 else
  hour = hour;

 if(hour == 0)
  hour = 12;
 else
  hour = hour;

 if(minutes<10)
  minutes = '0' + minutes;
 else
  minutes = minutes;

 if(seconds<10)
  seconds = '0' + seconds;
 else
  seconds = seconds;

 hourComplete = hour + ':' + minutes + ':' + seconds + ' ' + timeZone;
 $('#watch').html('<i class="fas fa-tachometer-alt"></i> '+hourComplete);
}

function showNow()
{
 var time = new Date();
 var day = time.getDate();
 var month = time.getMonth()+1;
 var year = time.getFullYear();
 var monthLetters;
 var dateComplete;

 if(day<10)
  day='0'+day;

 if(month<10)
  month=0+month;

 monthLetters = cambiarmonth(month);

 dateComplete = monthLetters + ' ' + day + ' de ' + year;
 $('#now').html(dateComplete);
}

function cambiarmonth(month)
{
 switch(month)
 {
  case 1:
   monthLetters = 'Enero';
   break;
  case 2:
   monthLetters = 'Febrero';
   break;
  case 3:
   monthLetters = 'Marzo';
   break;
  case 4:
   monthLetters = 'Abril';
   break;
  case 5:
   monthLetters = 'Mayo';
   break;
  case 6:
   monthLetters = 'Junio';
   break;
  case 7:
   monthLetters = 'Julio';
   break;
  case 8:
   monthLetters = 'Agosto';
   break;
  case 9:
   monthLetters = 'Septiembre';
   break;
  case 10:
   monthLetters = 'Octubre';
   break;
  case 11:
   monthLetters = 'Noviembre';
   break;
  case 12:
   monthLetters = 'Diciembre';
   break;
 }
 return monthLetters;
}

$(document).ready(function()
{
 setInterval(showWatch, 1000);
 setInterval(showNow, 1000);
});
