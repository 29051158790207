window._ = require('lodash');

try
{
 window.$ = window.jQuery = require('jquery');
 window.jQuery_Easing = require('jquery.easing');
 window.Popper = require('popper.js').default;
 window.Bootstrap = require('bootstrap');
 window.Bootstrap_Select = require('bootstrap-select');
 window.moment = require('moment');
 window.Datetimepicker = require('./datetimepicker/bootstrap-datetimepicker.min.js');
 window.Datatable_Bs4 = require('datatables.net-bs4');
 window.Datatable_Select_Bs4 = require('datatables.net-select-bs4');
 window.Swal = require('sweetalert2');
 window.Select = require('./select2/select2.min.js');
 window.Time = require('./time/time.js');
 window.Base = require('./other/base');
}
catch(e){}

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if(token){
 window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
}
else{
 console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}
