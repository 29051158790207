class Base
{
 activateEditor()
 {
  if($('#descriptionContent').length)
  {
   tinymce.init({
    language : 'es_419',
    plugins: 'table',
    selector: '#descriptionContent'
   });
  }
 }

 activateSidebar()
 {
  let treeviewMenu = $('.app-menu');

  $('[data-toggle="sidebar"]').click(function(event)
  {
   event.preventDefault();
   $('.app').toggleClass('sidenav-toggled');
  });

  $("[data-toggle='treeview']").click(function(event)
  {
   event.preventDefault();
   if(!$(this).parent().hasClass('is-expanded')){
    treeviewMenu.find("[data-toggle='treeview']").parent().removeClass('is-expanded');
   }
   $(this).parent().toggleClass('is-expanded');
  });

  $("[data-toggle='treeview.'].is-expanded").parent().toggleClass('is-expanded');
  $("[data-toggle='tooltip']").tooltip();
 }

 approveOrder()
 {
  $('.approveOrder').click(function()
  {
   const consecutive = $(this).attr('id');

   Swal.fire({
    icon: "success",
    title: "<h5>¿Realmente deseas aprobar esta solicitud?</h5>",
    html: "<h6 class='text-center'>La orden pasará a estado 'Aprobado' y no será posible cancelarla.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/manage_order/${consecutive}`, {
      method: 'POST',
      body: consecutive,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>La solicitud seleccionada ha sido aprobada</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha aprobado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha aprobado la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha aprobado la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha aprobado la solicitud porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha aprobado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha aprobado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha aprobado la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La solicitud seleccionada no ha sido aprobada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 cancelOrder()
 {
  $('.cancelOrder').click(function()
  {
   const consecutive = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar esta solicitud?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/order/${consecutive}`, {
      method: 'DELETE',
      body: consecutive,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>La solicitud seleccionada ha sido eliminada</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La solicitud seleccionada no ha sido eliminada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeAsset()
 {
  $('.changeAsset').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de este elemento?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede aparecer o no al momento de gestionar solicitudes.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/asset/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>El elemento seleccionado ha sido activado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>El elemento seleccionado ha sido desactivado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del elemento, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del elemento porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del elemento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del elemento porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del elemento, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado del elemento, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del elemento porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El elemento seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeCategory()
 {
  $('.changeCategory').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de esta categoría?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede aparecer o no al momento de crear/editar activos.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/category/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>La categoría seleccionada ha sido activada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>La categoría seleccionada ha sido desactivada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado de la categoría, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la categoría porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la categoría porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la categoría porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado de la categoría, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado de la categoría, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la categoría porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La categoría seleccionada no ha sido afectada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeCompany()
 {
  $('.changeCompany').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de esta empresa?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede aparecer o no al momento de crear/editar usuarios, activos ó solicitudes.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/company/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>La empresa seleccionada ha sido activada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>La empresa seleccionada ha sido desactivada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado de la empresa, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la empresa porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la empresa porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la empresa porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado de la empresa, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado de la empresa, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la empresa porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La empresa seleccionada no ha sido afectada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeEps()
 {
  $('.changeEps').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de esta eps?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede aparecer o no al momento de crear/editar usuarios.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/eps/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>La eps seleccionada ha sido activada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>La eps seleccionada ha sido desactivada</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado de la eps, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la eps porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la eps porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la eps porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado de la eps, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado de la eps, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado de la eps porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La eps seleccionada no ha sido afectada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 changeTypeFile()
 {
  jQuery('.input-invoice').change(function()
  {
   let filename = jQuery(this).val().split('\\').pop();
   let label = $("label[for='" + $(this).attr('id') + "']");

   $(label).text(filename);
   $(label).addClass('text-center');

   $('.btn-primary').removeClass('d-none');
  });
 }

 changeUser()
 {
  $('.changeUser').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas cambiar el estado de este usuario?</h5>",
    html: "<h6 class='text-justify'>Al hacerlo puede habilitarle o negarle el acceso a la plataforma.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/user/change/${id}`, {
      method: 'PUT',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      if(data=='Activo')
      {
       Swal.fire({
        icon: "success",
        title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
        html: "<h5 class='text-center'>El usuario seleccionado ha sido activado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }
      else
      {
       Swal.fire({
        icon: "warning",
        title: "<h6 class='text-center'>¡Atención!</h6>",
        html: "<h5 class='text-center'>El usuario seleccionado ha sido desactivado</h5>",
        confirmButtonColor: '#FFA500'
       })
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha actualizado el estado del usuario, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha actualizado el estado del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El usuario seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 deleteOrder()
 {
  $('.deleteOrder').click(function()
  {
   const consecutive = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar esta solicitud?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/manage_order/${consecutive}`, {
      method: 'DELETE',
      body: consecutive,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>La solicitud seleccionada ha sido eliminada</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado la solicitud, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La solicitud seleccionada no ha sido eliminada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 destroyAccount()
 {
  $('.destroyAccount').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar esta cuenta de cobro?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/account/${id}`, {
      method: 'DELETE',
      body: id,
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>La cuenta de cobro seleccionada ha sido eliminada</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado la cuenta de cobro, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la cuenta de cobro porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la cuenta de cobro porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la cuenta de cobro porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado la cuenta de cobro, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado la cuenta de cobro, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado la cuenta de cobro porque su sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>La cuenta de cobro seleccionada no ha sido eliminada.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 destroyRole()
 {
  $('.destroyRole').click(function()
  {
   const id = $(this).attr('id');

   Swal.fire({
    icon: "warning",
    title: "<h5>¿Realmente deseas eliminar este rol?</h5>",
    html: "<h6 class='text-center'>Esta acción es irreversible.</h6>",
    confirmButtonText: "Sí, procede",
    confirmButtonColor: '#FFA500',
    cancelButtonText: "No",
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false
   })
   .then((result) => {
    if(result.value)
    {
     const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

     fetch(`/role/${id}`, {
      method: 'DELETE',
      headers: {
       "Content-Type": "application/json",
       "X-CSRF-Token": token
      }
     })
     .then(function(response)
     {
      if(!response.ok){
       throw Error(response.status);
      }

      return response.text();
     })
     .then(function(data)
     {
      Swal.fire({
       icon: "success",
       title: "<h6 class='text-center'>¡Buen Trabajo!</h6>",
       html: "<h5 class='text-center'>El rol seleccionado ha sido eliminado</h5>",
       confirmButtonColor: '#FFA500'
      })

      setTimeout(function(){ window.location.reload(); }, 1500);
     })
     .catch(function(error)
     {
      switch (error)
      {
       case 'Error: 400':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha hecho la petición de forma correcta. No se ha eliminado el rol, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 403':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5 class='text-center'>Acceso denegado.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 404':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el rol porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 405':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el rol porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 500':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el rol porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 504':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se ha eliminado el rol, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       case 'Error: 509':
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>Se ha superado el ancho de banda disponible. No se ha eliminado el rol, por favor vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;

       default:
        Swal.fire({
         icon: "error",
         title: "<h6 class='text-center'>Atención</h6>",
         html: "<h5>No se ha eliminado el rol porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
         confirmButtonColor: '#FFA500'
        })
        break;
      }

      setTimeout(function(){ window.location.reload(); }, 1500);
     });
    }
    else
    {
     Swal.fire({
      icon: "success",
      title: "<h5 class='text-center'>Sin Cambios</h5>",
      html: "<h6 class='text-center'>El rol seleccionado no ha sido afectado.</h6>",
      confirmButtonColor: '#FFA500'
     })
    }
   })
  });
 }

 detectBrowser()
 {
  let getBrowserInfo = function()
  {
   let ua= navigator.userAgent, tem,
   M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];

   if(/trident/i.test(M[1]))
   {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return 'IE '+(tem[1] || '');
   }

   if(M[1]=== 'Chrome')
   {
    tem= ua.match(/\b(OPR|Edge)\/(\d+)/);

    if(tem!= null)
     return tem.slice(1).join(' ').replace('OPR', 'Opera');
   }

   M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];

   if((tem= ua.match(/version\/(\d+)/i))!= null)
    M.splice(1, 1, tem[1]);

   return M.join(' ');
  };

  if(getBrowserInfo().substr(0,2)=='IE'){
   alert('¡Atención!, Esta usando Internet Explorer, este navegador no es compatible con algunas funciones del sitio web, por favor utilice otro navegador para una mejor experiencia, preferiblemente Google Chrome o Firefox');
  }
 }

 displayOrder()
 {
  $('.displayOrder').click(function()
  {
   const consecutive = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/manage_order/${consecutive}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name_proyect,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Solicitante: '+data.user+'</p>'
      +' '+
      '<p class="m-0 small">Cliente: '+data.client+'</p>'
      +' '+
      '<p class="m-0 small">Contacto Cliente: '+data.contact+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Salida Activos: '+data.date_exit+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Regreso Activos: '+data.date_input+'</p>'
      +' '+
      '<p class="m-0 small">Observaciones: '+data.observation+'</p>'
      +' '+
      '<p class="m-0 small">Condición de la Solicitud: '+data.condition+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos de la solicitud, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado de la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado de la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la solicitud porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos de la solicitud, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos de la solicitud, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 getTableAsset()
 {
  const container_assets = document.querySelector("#container_assets");
  const date_exit = parseInt((new Date($("#date_exit_filter").html()).getTime()/1000).toFixed(0));
  const date_input = parseInt((new Date($("#date_input_filter").html()).getTime()/1000).toFixed(0));
  const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

  let array_id = [];
  let array_name = [];

  let table  = $('#assetTable').DataTable({
   "ajax":{
    "url": '/order/table',
    "headers": {
     'X-CSRF-Token': token
    },
    "data": { date_exit, date_input },
    "type": 'post'
   },
   "columns":[
    {data: 'name_asset', name: 'name_asset'},
    {data: 'description_asset', name: 'description_asset'},
    {data: 'serial', name: 'serial'},
    {data: 'name_company', name: 'name_company'},
    {data: 'name_user', name: 'name_user'},
    {data: 'novelty', name: 'novelty'}
   ],
   "destroy": true,
   "info": false,
   "lengthMenu": [[10, 20, 30, 40, 50, 60, 70, 80, -1], [10, 20, 30, 40, 50, 60, 70, 80, "Todos"]],
   "paging": true,
   "processing": true,
   "responsive": true,
   "select": 'multi',
   "serverSide": false
  }).on('select', function(e, dt, type, indexes)
  {
   if(type==='row')
   {
    let id = table.rows(indexes).data()[0]['id_asset'];
    let name = table.rows(indexes).data()[0]['name_asset']+'('+table.rows(indexes).data()[0]['serial']+')';

    array_id.push(id);
    $('#id_asset').val(array_id);

    let li = document.createElement("li");
    li.setAttribute("id", "div"+id);

    li.innerText = name;
    container_assets.appendChild(li);
   }
  }).on('deselect', function(e, dt, type, indexes)
  {
   if(type==='row')
   {
    let id = table.rows(indexes).data()[0]['id_asset'];
    let name = table.rows(indexes).data()[0]['name_asset']+'('+table.rows(indexes).data()[0]['serial']+')';

    array_id.pop(id);
    $('#id_asset').val(array_id);

    let li = document.querySelector("#div"+id);
    container_assets.removeChild(li);
   }
  });
 }

 loginContent()
 {
  $('.login-content [data-toggle="flip"]').click(function()
  {
   $('.login-box').toggleClass('flipped');

   return false;
  });
 }

 logout()
 {
  let url = '/session';
  let minutes = 720;

  fetch(url)
   .then(function(response){
    if(response.ok){
     return response.text();
    }
   })
   .catch(function(error)
   {
    Swal.fire({
     icon: "error",
     title: "<h6 class='text-center'>Atención</h6>",
     html: "<h5 class='text-center'>Por favor revise su conexión a internet.</h5>",
     confirmButtonColor: '#FFA500'
    })
   })
   .then(function(data)
   {
    if(data!='No logueado')
    {
     let number = 60000*minutes;

     setTimeout( function()
     {
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Su sesión ha caducado, por favor vuelva a loguearse.</h5>",
       confirmButtonColor: '#FFA500'
      })

      location.href = '/logout';
     }, number);
    }
   });
 }

 searchSelect()
 {
  $('#search').select2({
   placeholder: "Buscar un término...",
   minimumInputLength: 3,
   ajax:{
    url: '/found',
    dataType: 'json',
    data: function(params){
     return{
      search: $.trim(params.term)
     };
    },
    processResults: function(data){
     return{
      results: data
     };
    },
    cache: true
   }
  });

  $("#search").change(function()
  {
   let route = $("#search").val();

   $(location).attr('href', '/redirectRoute/'+route);
  });
 }

 select(){
  $('.select_input').select2();
 }

 selectDate()
 {
  $(".readonly").keydown(function(e){
   e.preventDefault();
  });

  $('.only_date').datetimepicker({
   allowInputToggle: true,
   useCurrent: false,
   ignoreReadonly: true,
   format: 'L',
  });

  $('#input_date').datetimepicker({
   allowInputToggle: true,
   useCurrent: false,
   defaultDate: moment(),
   ignoreReadonly: true,
  });

  $('#input_exit').datetimepicker({
   allowInputToggle: true,
   useCurrent: false,
   defaultDate: moment(),
   ignoreReadonly: true,
  });

  $('#input_start').datetimepicker({
   allowInputToggle: true,
   useCurrent: false,
   ignoreReadonly: true,
  });

  $('#input_end').datetimepicker({
   allowInputToggle: true,
   useCurrent: false,
   ignoreReadonly: true,
  });

  $("#input_date").on("dp.change", function(e){
   $('#input_exit').data("DateTimePicker").minDate(e.date);
  });
  $("#input_exit").on("dp.change", function(e){
   $('#input_date').data("DateTimePicker").maxDate(e.date);
  });

  $("#input_start").on("dp.change", function(e){
   $('#input_end').data("DateTimePicker").minDate(e.date);
  });
  $("#input_end").on("dp.change", function(e){
   $('#input_start').data("DateTimePicker").maxDate(e.date);
  });
 }

 showAccount()
 {
  $('.showAccount').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/account/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.consecutive,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Consecutivo: '+data.consecutive+'</p>'
      +' '+
      '<p class="m-0 small">Ciudad: '+data.city+'</p>'
      +' '+
      '<p class="m-0 small">Fecha: '+data.date+'</p>'
      +' '+
      '<p class="m-0 small">Valor: '+data.value+'</p>'
      +' '+
      '<p class="m-0 small">Valor en Letras: '+data.value_letters+'</p>'
      +' '+
      '<p class="m-0 small">Concepto: '+data.concept+'</p>'
      +' '+
      '<p class="m-0 small">Descripción: '+data.description+'</p>'
      +' '+
      '<p class="m-0 small">Banco: '+data.bank+'</p>'
      +' '+
      '<p class="m-0 small">Número Cuenta: '+data.number+'</p>'
      +' '+
      '<p class="m-0 small">Tipo Cuenta: '+data.type+'</p>'
      +' '+
      '<p class="m-0 small">Condición: '+data.condition+'</p>'
      +' '+
      '<p class="font-weight-bold m-0 small">Observaciones: '+data.observation+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showAccountManage()
 {
  $('.showAccountManage').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/manage_account/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.consecutive,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Consecutivo: '+data.consecutive+'</p>'
      +' '+
      '<p class="m-0 small">Usuario: '+data.user+'</p>'
      +' '+
      '<p class="m-0 small">Ciudad: '+data.city+'</p>'
      +' '+
      '<p class="m-0 small">Fecha: '+data.date+'</p>'
      +' '+
      '<p class="m-0 small">Valor: '+data.value+'</p>'
      +' '+
      '<p class="m-0 small">Valor en Letras: '+data.value_letters+'</p>'
      +' '+
      '<p class="m-0 small">Concepto: '+data.concept+'</p>'
      +' '+
      '<p class="m-0 small">Descripción: '+data.description+'</p>'
      +' '+
      '<p class="m-0 small">Banco: '+data.bank+'</p>'
      +' '+
      '<p class="m-0 small">Número Cuenta: '+data.number+'</p>'
      +' '+
      '<p class="m-0 small">Tipo Cuenta: '+data.type+'</p>'
      +' '+
      '<p class="m-0 small">Condición: '+data.condition+'</p>'
      +' '+
      '<p class="font-weight-bold m-0 small">Observaciones: '+data.observation+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showAsset()
 {
  $('.showAsset').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/asset/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Elemento: '+data.name+'</p>'
      +' '+
      '<p class="m-0 small">Detalle: '+data.description+'</p>'
      +' '+
      '<p class="m-0 small">Marca: '+data.mark+'</p>'
      +' '+
      '<p class="m-0 small">Color: '+data.color+'</p>'
      +' '+
      '<p class="m-0 small">Serial: '+data.serial+'</p>'
      +' '+
      '<p class="m-0 small">Precio por Día: '+data.price_day+'</p>'
      +' '+
      '<p class="m-0 small">Precio Mensual: '+data.price_month+'</p>'
      +' '+
      '<p class="m-0 small">Novedades: '+data.novelty+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Inicio Alquiler: '+data.date_start+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Fin Alquiler: '+data.date_end+'</p>'
      +' '+
      '<p class="m-0 small">Empresa Propietaria: '+data.company+'</p>'
      +' '+
      '<p class="m-0 small">Asignado a: '+data.user+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showCompany()
 {
  $('.showCompany').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/company/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Nit: '+data.nit+'</p>'
      +' '+
      '<p class="m-0 small">Dirección: '+data.address+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono: '+data.phone+'</p>'
      +' '+
      '<p class="m-0 small">Actividad Comercial: '+data.description+'</p>'
      +' '+
      '<p class="m-0 small">Representante: '+data.representative+'</p>'
      +' '+
      '<p class="m-0 small">Email: '+data.email+'</p>'
      +' '+
      '<p class="m-0 small">Ciudad: '+data.city+'</p>'
      +' '+
      '<p class="m-0 small">País: '+data.country+'</p>'
      +' '+
      '<p class="m-0 small">Tipo de Empresa: '+data.type+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+data.state+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos de la empresa, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado de la empresa porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado de la empresa porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la empresa porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos de la empresa, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos de la empresa, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la empresa porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showContent()
 {
  $('.showContent').click(function()
  {
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/content/show`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: "Notas",
     confirmButtonColor: '#FFA500',
     html: `<div>${ data.description }</div>`
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showOrder()
 {
  $('.showOrder').click(function()
  {
   const consecutive = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/order/${consecutive}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name_proyect,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Solicitante: '+data.user+'</p>'
      +' '+
      '<p class="m-0 small">Cliente: '+data.client+'</p>'
      +' '+
      '<p class="m-0 small">Contacto Cliente: '+data.contact+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Salida Activos: '+data.date_exit+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Regreso Activos: '+data.date_input+'</p>'
      +' '+
      '<p class="m-0 small">Observaciones: '+data.observation+'</p>'
      +' '+
      '<p class="m-0 small">Condición de la Solicitud: '+data.condition+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos de la solicitud, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado de la solicitud porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado de la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la solicitud porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos de la solicitud, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos de la solicitud, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos de la solicitud porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showPermission()
 {
  $('.showPermission').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/permission/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">'
      +' '+
      '<span class="text-success">Roles: </span>'+data.name_role+'</p>'
      +' '+
      '<p class="m-0 small">'
      +' '+
      '<span class="text-warning">Permisos Individuales: </span>'+data.name_permission+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showSchedule()
 {
  $('.showSchedule').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/schedule/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Hora de Salida Almuerzo: '+data.exit_lunch+'</p>'
      +' '+
      '<p class="m-0 small">Hora de Entrada Almuerzo: '+data.input_lunch+'</p>'
      +' '+
      '<p class="m-0 small">Comentario de Entrada: '+data.note_input+'</p>'
      +' '+
      '<p class="m-0 small">Comentario de Salida: '+data.note_exit+'</p>'
      +' '+
      '<p class="m-0 small">Comentario de Salida Almuerzo: '+data.note_exit_lunch+'</p>'
      +' '+
      '<p class="m-0 small">Comentario de Entrada Almuerzo: '+data.note_input_lunch+'</p>'
      +' '+
      '<p class="m-0 small">Ip Entrada: '+data.ip_input+'</p>'
      +' '+
      '<p class="m-0 small">Ip Salida Almuerzo: '+data.ip_exit_lunch+'</p>'
      +' '+
      '<p class="m-0 small">Ip Entrada Almuerzo: '+data.ip_input_lunch+'</p>'
      +' '+
      '<p class="m-0 small">Ip Salida: '+data.ip_exit+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos solicitados, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos solicitados porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }

 showUser()
 {
  $('.showUser').click(function()
  {
   const id = $(this).attr('id');
   const token = document.querySelector("meta[name='csrf-token']").getAttribute("content");

   fetch(`/user/${id}`, {
    method: 'GET',
    headers: {
     "Content-Type": "application/json",
     "X-CSRF-Token": token
    }
   })
   .then(function(response)
   {
    if(!response.ok){
     throw Error(response.status);
    }

    return response.json();
   })
   .then(function(data)
   {
    Swal.fire({
     icon: "success",
     title: data.name,
     confirmButtonColor: '#FFA500',
     html:
      '<div class=text-left>'
      +' '+
      '<p class="m-0 small">Tipo Documento: '+data.document_type+'</p>'
      +' '+
      '<p class="m-0 small">N° Documento: '+data.identification+'</p>'
      +' '+
      '<p class="m-0 small">Teléfono: '+data.phone+'</p>'
      +' '+
      '<p class="m-0 small">Cargo: '+data.charge+'</p>'
      +' '+
      '<p class="m-0 small">Email: '+data.email+'</p>'
      +' '+
      '<p class="m-0 small">Empresa: '+data.company+'</p>'
      +' '+
      '<p class="m-0 small">Contrato: '+data.contract+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Expedición Cédula: '+data.expedition_date+'</p>'
      +' '+
      '<p class="m-0 small">Fecha Nacimiento: '+data.birthdate+'</p>'
      +' '+
      '<p class="m-0 small">Grupo Sanguineo: '+data.rh+'</p>'
      +' '+
      '<p class="m-0 small">Eps: '+data.eps+'</p>'
      +' '+
      '<p class="m-0 small">Dirección: '+data.address+'</p>'
      +' '+
      '<p class="m-0 small">Estado: '+data.state+'</p>'
      +' '+
      '</div>',
    })
   })
   .catch(function(error)
   {
    switch(error)
    {
     case 'Error: 400':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se ha hecho la petición de forma correcta. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 403':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5 class='text-center'>Acceso denegado.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 404':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque no se ha logrado establecer la conexión con el servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 405':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 500':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque no se recibido una respuesta del servidor, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 504':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>El tiempo de respuesta del servidor ha alcanzado el máximo tiempo de límite. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     case 'Error: 509':
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>Se ha superado el ancho de banda disponible. No se han recuperado los datos del usuario, por favor vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;

     default:
      Swal.fire({
       icon: "error",
       title: "<h6 class='text-center'>Atención</h6>",
       html: "<h5>No se han recuperado los datos del usuario porque tu sesión ha caducado, por favor logueate y vuelve a intentarlo.</h5>",
       confirmButtonColor: '#FFA500'
      })
      break;
    }

    setTimeout(function(){ window.location.reload(); }, 1500);
   });
  })
 }
}

window.onload = () => {
 let base = new Base();

 base.activateEditor();
 base.activateSidebar();
 base.approveOrder();
 base.cancelOrder();
 base.changeAsset();
 base.changeCategory();
 base.changeCompany();
 base.changeEps();
 base.changeTypeFile();
 base.changeUser();
 base.deleteOrder();
 base.destroyAccount();
 base.destroyRole();
 base.detectBrowser();
 base.displayOrder();
 base.getTableAsset();
 base.loginContent();
 base.logout();
 base.searchSelect();
 base.select();
 base.selectDate();
 base.showAccount();
 base.showAccountManage();
 base.showAsset();
 base.showCompany();
 base.showContent();
 base.showOrder();
 base.showPermission();
 base.showSchedule();
 base.showUser();
};
